import React, { useState } from "react";

import { Button, TextField } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import clsx from "clsx";

import LoadingError from "~/components/Loaders/LoadingError";
import { filterFromValidItems } from "~/utils/api/v2/items";
import { Item, SpecificItem } from "~/utils/interfaces/Items";

type ItemsTableProps = {
  onClick: ({ id, name }: SpecificItem) => void;
};

const ItemsTable = ({ onClick }: ItemsTableProps) => {
  const [items, setItems] = useState([] as Item[]); //ver type
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  const [nameFilter, setNameFilter] = useState<string>("");

  const displayTableRows = items.map((item: any) => {
    return (
      <TableRow
        key={item.id}
        hover
        style={{ cursor: "pointer" }}
        onClick={() => {
          onClick(item);
        }}
      >
        <TableCell>{item.name}</TableCell>
      </TableRow>
    );
  });

  const handleFilter = async () => {
    setLoading(true);
    try {
      const req = await filterFromValidItems(nameFilter);
      if (req && req.data.results.length > 0) {
        setItems(req.data.results);
        setLoading(false);
      } else {
        setError("No se encontraron servicios con ese nombre");
        setLoading(false);
      }
    } catch (err) {
      setError(err);
    }
  };

  return (
    <div className="m-4">
      <LoadingError
        loading={loading}
        error={error}
      />
      {!loading && (
        <>
          <div className={clsx("flex flex-row", "w-full gap-x-2", "justify-between")}>
            <TextField
              fullWidth
              label="Nombre"
              variant="standard"
              onChange={(e) => setNameFilter(e.target.value)}
              className="w-56"
              value={nameFilter}
            />
            <Button
              fullWidth
              variant="outlined"
              className="h-8 w-56 my-3 lg:my-0"
              color="primary"
              onClick={handleFilter}
            >
              Filtrar
            </Button>
          </div>
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <p className="font-bold">Nombre</p>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{displayTableRows}</TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </div>
  );
};

export default ItemsTable;
