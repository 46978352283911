import React, { useEffect, useState } from "react";

import { Button, ButtonGroup } from "@material-ui/core";
import { navigate } from "gatsby";

import PrivateRoute from "~/components/Authentication/PrivateRoute";
import LoadingError from "~/components/Loaders/LoadingError";
import AddCouponModal from "~/components/Subscriptions/AddCouponModal";
import CouponDetail from "~/components/Subscriptions/CouponDetail";
import EditSubscriptionModal from "~/components/Subscriptions/EditSubscriptionModal";
import IncludedService from "~/components/Subscriptions/IncludedService";
import PauseSubscriptionModal from "~/components/Subscriptions/PauseSubscriptionModal";
import subscriptionsAPI from "~/utils/api/v2/subscriptions";
import { FREQUENCY_TRANSLATED } from "~/utils/constants/subscriptions";
import Subscription from "~/utils/interfaces/Subscriptions";

interface Props {
  id: string;
}

const IndexPage = ({ id }: Props): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  const [subscription, setSubscription] = useState<Subscription>();
  const [refetch, setRefetch] = useState<boolean>(false);
  const [isEditSubscriptionModalOpen, setIsEditSubscriptionModalOpen] = useState<boolean>(false);
  const [pauseSubscriptionModalOpen, setPauseSubscriptionModalOpen] = useState<boolean>(false);
  const [isAddCouponModalOpen, setIsAddCouponModalOpen] = useState<boolean>(false);

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      setLoading(true);
      try {
        const req = await subscriptionsAPI.getSubscriptionDetail(id);
        setSubscription(req.data);
      } catch (err) {
        setError(err);
      }
      setLoading(false);
    };

    fetchData();
  }, [refetch]);

  const handleRefetch = (): void => {
    setRefetch(!refetch);
  };

  const handleCloseEditSubscriptionModal = (reload?: boolean): void => {
    setIsEditSubscriptionModalOpen(false);
    if (reload) {
      handleRefetch();
    }
  };

  const handleClosePauseSubscriptionModal = (reload?: boolean): void => {
    setPauseSubscriptionModalOpen(false);
    if (reload) {
      handleRefetch();
    }
  };

  const handleCloseAddCouponModal = (reload?: boolean): void => {
    setIsAddCouponModalOpen(false);
    if (reload) {
      handleRefetch();
    }
  };

  return (
    <PrivateRoute>
      {subscription && (
        <>
          <EditSubscriptionModal
            isOpen={isEditSubscriptionModalOpen}
            closeModal={handleCloseEditSubscriptionModal}
            subscription={subscription}
            subscriptionId={subscription.id}
          />
          <PauseSubscriptionModal
            isOpen={pauseSubscriptionModalOpen}
            closeModal={handleClosePauseSubscriptionModal}
            subscription={subscription}
          />
          <AddCouponModal
            isOpen={isAddCouponModalOpen}
            closeModal={handleCloseAddCouponModal}
            subscriptionId={subscription.id}
          />
        </>
      )}
      <LoadingError
        loading={loading}
        error={error}
      />
      {!loading && subscription && (
        <>
          <div className="bg-gray-200 rounded-lg flex flex-col gap-y-5 text-left p-16">
            <h3>
              Suscripción de {subscription.user.first_name} ${subscription.user.last_name}
            </h3>
            <div className="flex flex-col gap-y-2">
              <span className="text-xl">Datos del paciente</span>
              <div>
                <span className="font-bold">Nombre: </span>
                {subscription.user.first_name} {subscription.user.last_name}
              </div>
              {subscription.user.document_type === "ci" && (
                <div>
                  <span className="font-bold">Rut: </span>
                  {subscription.user.document_number}
                </div>
              )}
              <div>
                <span className="font-bold">Email: </span>
                {subscription.user.email}
              </div>
              <div>
                <span className="font-bold">Teléfono: </span>
                {subscription.user.phone}
              </div>
            </div>
            <div className="flex flex-col gap-y-2">
              <span className="text-xl">Datos de la suscripción</span>
              <div>
                <span className="font-bold">Monto a cobrar:</span> ${subscription.base_price}
              </div>
              <div>
                <span className="font-bold">Fecha de cobro:</span> {subscription.billing_date}
              </div>
              <div>
                <span className="font-bold">Frecuencia de cobro:</span>{" "}
                {FREQUENCY_TRANSLATED[subscription.billing_frequency]}
              </div>
              {subscription.monthly_discounts.length > 0 && (
                <>
                  <span className="font-bold">Descuentos mensuales: </span>
                  {subscription.monthly_discounts
                    .filter((item) => item > 0)
                    .map((item, number) => (
                      <div className="ml-2">
                        <div className="-my-1">
                          <span className="font-bold">{number + 1}° mes: </span>
                          {item}%
                        </div>
                      </div>
                    ))}
                </>
              )}
              {subscription.subscription_items.length > 0 && (
                <>
                  <span className="font-bold">Servicios incluídos: </span>
                  {subscription.subscription_items
                    .filter((item) => item.active)
                    .map((item) => (
                      <IncludedService
                        item={item}
                        subscriptionId={subscription.id}
                        refetch={handleRefetch}
                      />
                    ))}
                </>
              )}
              <ButtonGroup
                variant="outlined"
                color="primary"
              >
                <Button onClick={async () => navigate(`/subscriptions/${subscription.id}/add-service`)}>
                  Añadir servicio
                </Button>
              </ButtonGroup>
              <ButtonGroup
                variant="outlined"
                color="primary"
              >
                <Button onClick={() => setIsEditSubscriptionModalOpen(true)}>Editar suscripción</Button>
              </ButtonGroup>
              {subscription.status === "active" && (
                <ButtonGroup
                  variant="outlined"
                  color="secondary"
                >
                  <Button onClick={() => setPauseSubscriptionModalOpen(true)}>Pausar suscripción</Button>
                </ButtonGroup>
              )}
              <span className="text-xl">Cupones: </span>
              {subscription.coupons.length > 0 && (
                <>
                  {subscription.coupons.map((coupon) => (
                    <>
                      {coupon.active && (
                        <CouponDetail
                          coupon={coupon}
                          refetch={handleRefetch}
                          subscriptionId={subscription.id}
                        />
                      )}
                    </>
                  ))}
                </>
              )}
              <ButtonGroup
                variant="outlined"
                color="primary"
              >
                <Button onClick={() => setIsAddCouponModalOpen(true)}>Agregar un cupón</Button>
              </ButtonGroup>
            </div>
          </div>
        </>
      )}
    </PrivateRoute>
  );
};

export default IndexPage;
