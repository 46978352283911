import React, { useState } from "react";

import MomentUtils from "@date-io/moment";
import { Button, ButtonGroup, FormControl, InputLabel, MenuItem, Select, TextField } from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

import LoadingError from "~/components/Loaders/LoadingError";
import subscriptionsAPI from "~/utils/api/v2/subscriptions";
import { FREQUENCY_TRANSLATED } from "~/utils/constants/subscriptions";
import Subscription from "~/utils/interfaces/Subscriptions";

type ItemType = {
  billing_date: string;
  billing_frequency: string;
  base_price: number;
  monthly_discounts: number[];
};

type EditSubscriptionModalProps = {
  isOpen: boolean;
  closeModal: (reload?: boolean) => void;
  subscription: Subscription;
  subscriptionId: string;
};

const EditSubscriptionModal = ({ isOpen, closeModal, subscription, subscriptionId }: EditSubscriptionModalProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  const [changes, setChanges] = useState<ItemType>({
    base_price: subscription.base_price,
    billing_date: subscription.billing_date,
    billing_frequency: subscription.billing_frequency,
    monthly_discounts: subscription.monthly_discounts,
  });

  const handleEdit = async () => {
    setLoading(true);

    try {
      await subscriptionsAPI.editSubscription(subscriptionId, changes);
      closeModal(true);
    } catch (err) {
      setError(err);
    }
    setLoading(false);
  };

  const frequencyOptions = (): JSX.Element => {
    return (
      <div>
        <FormControl margin="normal">
          <InputLabel>Frecuencia de facturación</InputLabel>
          <Select
            autoWidth
            value={changes.billing_frequency}
            onChange={(e) => {
              setChanges({
                ...changes,
                billing_frequency: e.target.value as string,
              });
            }}
            className="w-56"
          >
            {Object.keys(FREQUENCY_TRANSLATED).map((key: string) => (
              <MenuItem
                value={key}
                key={key}
              >
                {FREQUENCY_TRANSLATED[key]}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    );
  };

  function handleChangeMonthlyDiscount(month: number, value: number) {
    const newDiscounts = changes.monthly_discounts;
    newDiscounts[month] = value;
    setChanges({ ...changes, monthly_discounts: newDiscounts });
  }

  function addDiscount() {
    const newDiscounts = changes.monthly_discounts;
    newDiscounts.push(1);
    setChanges({ ...changes, monthly_discounts: newDiscounts });
  }

  return (
    <Modal
      open={isOpen}
      onClose={() => {
        closeModal();
        document.getElementById("navbar").style.zIndex = "1999";
      }}
      style={{ height: "100%" }}
    >
      <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white p-8 rounded-lg shadow-lg">
        <LoadingError
          loading={loading}
          error={error}
        />
        {!loading && (
          <div className="flex flex-col gap-y-2">
            <div className="text-xl mb-2">Editar suscripción</div>
            <MuiPickersUtilsProvider
              utils={MomentUtils}
              locale="es"
            >
              <KeyboardDatePicker
                variant="inline"
                format="LL"
                margin="normal"
                label="Fecha de facturación"
                value={changes.billing_date}
                autoOk
                onChange={(date: moment.Moment) => {
                  setChanges({ ...changes, billing_date: date.format("YYYY-MM-DD") });
                }}
              />
            </MuiPickersUtilsProvider>
            {frequencyOptions()}
            <TextField
              fullWidth
              type="number"
              label="Precio a cobrar"
              variant="standard"
              onChange={(e) => setChanges({ ...changes, base_price: parseInt(e.target.value) })}
              className="w-56"
              value={changes.base_price}
              InputProps={{
                inputProps: { min: 0 },
              }}
            />
            {changes.monthly_discounts.length > 0 && (
              <div className="mt-4">
                {changes.monthly_discounts.map((discount, number) => (
                  <div className="-my-1">
                    <span className="font-bold">{number + 1}° mes: </span>
                    <TextField
                      fullWidth
                      type="number"
                      label="Descuento"
                      variant="standard"
                      onChange={(e) => handleChangeMonthlyDiscount(number, parseInt(e.target.value))}
                      className="w-56"
                      value={changes.monthly_discounts[number]}
                      InputProps={{
                        inputProps: { min: 0 },
                      }}
                    />
                  </div>
                ))}
              </div>
            )}
            <span>Descuentos por meses: </span>
            <button onClick={addDiscount} className="ml-4">
              <AddCircleIcon />
            </button>
            <div className="flex flex-row justify-between mt-3">
              <ButtonGroup
                variant="outlined"
                color="primary"
              >
                <Button onClick={handleEdit}>Editar</Button>
              </ButtonGroup>
              <ButtonGroup
                variant="outlined"
                color="primary"
              >
                <Button onClick={() => closeModal()}>Cancelar</Button>
              </ButtonGroup>
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default EditSubscriptionModal;
